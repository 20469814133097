<template>
  <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_147_14957"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="20"
      height="24"
    >
      <path
        d="M18.3824 24H1.61765C0.724246 24 0 23.2325 0 22.2857V1.71429C0 0.767512 0.724246 0 1.61765 0H12.5L20 7.94805V22.2857C20 23.2325 19.2757 24 18.3824 24Z"
        fill="url(#paint0_linear_147_14957)"
      />
    </mask>
    <g mask="url(#mask0_147_14957)">
      <path
        d="M18.3824 24H1.61765C0.724246 24 0 23.2325 0 22.2857V1.71429C0 0.767512 0.724246 0 1.61765 0H12.5L20 7.94805V22.2857C20 23.2325 19.2757 24 18.3824 24Z"
        fill="url(#paint1_linear_147_14957)"
      />
      <g filter="url(#filter0_f_147_14957)">
        <g style="mix-blend-mode: darken">
          <path
            d="M16.6175 11.6104H3.5293V18.1558H16.6175V11.6104Z"
            fill="#0B07C6"
            fill-opacity="0.16"
          />
        </g>
      </g>
      <g filter="url(#filter1_d_147_14957)">
        <path
          d="M20 7.94805H14.1176C13.2243 7.94805 12.5 7.18054 12.5 6.23377V0L20 7.94805Z"
          fill="url(#paint2_linear_147_14957)"
        />
      </g>
      <path
        d="M5.24219 11.7344L6.33789 14.8281L7.42773 11.7344H8.58203V16H7.7002V14.834L7.78809 12.8213L6.63672 16H6.0332L4.88477 12.8242L4.97266 14.834V16H4.09375V11.7344H5.24219ZM10.2285 14.4971V16H9.34961V11.7344H11.0137C11.334 11.7344 11.6152 11.793 11.8574 11.9102C12.1016 12.0273 12.2891 12.1943 12.4199 12.4111C12.5508 12.626 12.6162 12.8711 12.6162 13.1465C12.6162 13.5645 12.4727 13.8945 12.1855 14.1367C11.9004 14.377 11.5049 14.4971 10.999 14.4971H10.2285ZM10.2285 13.7852H11.0137C11.2461 13.7852 11.4229 13.7305 11.5439 13.6211C11.667 13.5117 11.7285 13.3555 11.7285 13.1523C11.7285 12.9434 11.667 12.7744 11.5439 12.6455C11.4209 12.5166 11.251 12.4502 11.0342 12.4463H10.2285V13.7852ZM15.6367 14.3945H16.1201V15.0771H15.6367V16H14.79V15.0771H13.041L13.0029 14.5439L14.7812 11.7344H15.6367V14.3945ZM13.8467 14.3945H14.79V12.8887L14.7344 12.9854L13.8467 14.3945Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_147_14957"
        x="-35.4707"
        y="-27.3896"
        width="91.0881"
        height="84.5454"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="19.5" result="effect1_foregroundBlur_147_14957" />
      </filter>
      <filter
        id="filter1_d_147_14957"
        x="-10.5"
        y="-24"
        width="57.5"
        height="57.948"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="1" />
        <feGaussianBlur stdDeviation="12.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.33 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_147_14957" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_147_14957"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_147_14957"
        x1="10"
        y1="0"
        x2="10"
        y2="24"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF7979" />
        <stop offset="1" stop-color="#E85555" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_147_14957"
        x1="10"
        y1="0"
        x2="10"
        y2="24"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#7CA9FF" />
        <stop offset="1" stop-color="#4377DC" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_147_14957"
        x1="16.25"
        y1="0"
        x2="16.25"
        y2="7.94805"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#4B7BF7" />
        <stop offset="1" stop-color="#1A4DD1" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "AI",
  mixins: [],
  props: {},
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
</style>
